/* .wrapper-1 {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  } */
  .wrapper-2 {
    padding: 30px;
    text-align: left;
    /* text-align: center; */
  }