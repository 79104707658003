html,
body {
  overflow-x: hidden;
}
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.input-group-addon {
  cursor: pointer;
}

.form-control {
  border: 1px solid #ccc;
  box-shadow: none;
}
.form-control:hover,
.form-control:focus,
.form-control:active {
  box-shadow: none;
}
.form-control:focus {
  border: 1px solid #e34219;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  border: 1px solid #34495e;
  border-radius: 0;
  box-shadow: none;
  margin: 10px 0 0 0;
  padding: 0;
  min-width: 300px;
  max-width: 100%;
  width: auto;
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before,
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  display: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td,
.bootstrap-datetimepicker-widget.dropdown-menu table th {
  border-radius: 0;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td.old,
.bootstrap-datetimepicker-widget.dropdown-menu table td.new {
  color: #bbb;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td.today:before {
  border-bottom-color: #0095ff;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td.active,
.bootstrap-datetimepicker-widget.dropdown-menu table td.active:hover,
.bootstrap-datetimepicker-widget.dropdown-menu table td span.active {
  background-color: #0095ff;
  text-shadow: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td.active.today:before,
.bootstrap-datetimepicker-widget.dropdown-menu
  table
  td.active:hover.today:before,
.bootstrap-datetimepicker-widget.dropdown-menu
  table
  td
  span.active.today:before {
  border-bottom-color: #fff;
}
.bootstrap-datetimepicker-widget.dropdown-menu table th {
  height: 40px;
  padding: 0;
  width: 40px;
}
.bootstrap-datetimepicker-widget.dropdown-menu table th.picker-switch {
  width: auto;
}
.bootstrap-datetimepicker-widget.dropdown-menu table tr:first-of-type th {
  border-bottom: 1px solid #34495e;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td.day {
  height: 32px;
  line-height: 32px;
  padding: 0;
  width: auto;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td span {
  border-radius: 0;
  height: 77px;
  line-height: 77px;
  margin: 0;
  width: 25%;
}
.bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months tbody tr td,
.bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years tbody tr td,
.bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades tbody tr td {
  padding: 0;
}
.bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades tbody tr td {
  height: 27px;
  line-height: 27px;
}
.bootstrap-datetimepicker-widget.dropdown-menu
  .datepicker-decades
  tbody
  tr
  td
  span {
  display: block;
  float: left;
  width: 50%;
  height: 46px;
  line-height: 46px !important;
  padding: 0;
}
.bootstrap-datetimepicker-widget.dropdown-menu
  .datepicker-decades
  tbody
  tr
  td
  span:not(.decade) {
  display: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu .timepicker-picker table td {
  padding: 0;
  width: 30%;
  height: 20px;
  line-height: 20px;
}
.bootstrap-datetimepicker-widget.dropdown-menu
  .timepicker-picker
  table
  td:nth-child(2) {
  width: 10%;
}
.bootstrap-datetimepicker-widget.dropdown-menu .timepicker-picker table td a,
.bootstrap-datetimepicker-widget.dropdown-menu .timepicker-picker table td span,
.bootstrap-datetimepicker-widget.dropdown-menu
  .timepicker-picker
  table
  td
  button {
  border: none;
  border-radius: 0;
  height: 56px;
  line-height: 56px;
  padding: 0;
  width: 100%;
}
.bootstrap-datetimepicker-widget.dropdown-menu
  .timepicker-picker
  table
  td
  span {
  color: #333;
  margin-top: -1px;
}
.bootstrap-datetimepicker-widget.dropdown-menu
  .timepicker-picker
  table
  td
  button {
  background-color: #fff;
  color: #333;
  font-weight: bold;
  font-size: 1.2em;
}
.bootstrap-datetimepicker-widget.dropdown-menu
  .timepicker-picker
  table
  td
  button:hover {
  background-color: #eee;
}

.bootstrap-datetimepicker-widget.dropdown-menu .picker-switch table td {
  border-top: 1px solid #34495e;
}
.bootstrap-datetimepicker-widget.dropdown-menu .picker-switch table td a,
.bootstrap-datetimepicker-widget.dropdown-menu .picker-switch table td span {
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0;
  width: 100%;
}

.todayText:before {
  content: "Today's Date";
}
.input-group-addon {
  cursor: pointer;
  margin-left: -40px;
  z-index: 0;
  padding: 0;
}
.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0px solid #ced4da;
  border-radius: 0;
  margin-left: -14px;
  padding-right: 20px;
}
.calc-i {
  position: absolute;
  right: 18px;
  z-index: 2;
  top: 0px;
}
.common-banner {
  padding-top: 10px;
}
.white-logo {
  width: 170px;
  text-align: center;
}
.login-banner {
  min-height: 500px !important;
}
.common-banner {
  background: linear-gradient(to right, #f39547 0%, #e7692b 100%);

  padding: 40px 15px 0;
}
.mt--300 {
  margin-top: -330px;
}
.form-booking {
  background: #fff;
  max-width: 660px;
  margin: 0 auto;
  box-shadow: 3px 5px 28.16px 3.84px rgb(0 0 0 / 5%);
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 10px;
}
.text-orange {
  color: #f16900 !important;
  font-size: 13px !important;
  line-height: 16px;
  padding-top: 2px;
}
.main-btn {
  background-image: linear-gradient(
    to right,
    #f8a753 0%,
    #f09819 51%,
    #e55e25 100%
  );
  min-width: 170px;
  padding: 14px 40px !important;
  border-radius: 50px;
  text-transform: uppercase;
  box-shadow: 3px 5px 28.16px 3.84px rgb(229 116 37 / 68%);
  border: none;
  color: white;
}
.pb-50 {
  padding-bottom: 50px;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: px;
  font-weight: 500;
}

select.form-control:not([size]):not([multiple]) {
  height: 40px;
}
.bootstrap-select:not([class*='col-']):not([class*='form-control']):not(
    .input-group-btn
  ) {
  width: 100%;
}
.mb-30 {
  margin-bottom: 20px;
}
.form-control {
  height: 40px;
}
.bootstrap-select > .dropdown-toggle {
  width: 100%;
  padding-right: 25px;
  z-index: 1;
  height: 40px;
}
.mt--10 {
  margin-top: -10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml--18 {
  margin-left: -18px;
}
@media (max-width: 786px) {
  .form-booking {
    margin: 20px;
    padding-top: 10px;
    padding: 16px;
  }
  .mt--8 {
    margin-top: 16px;
  }
  .ml-22 {
    margin-left: 22px;
  }
 
}
.form-booking{
  margin-bottom: 16px !important;
}


label.error {
  display: block;
  color: red;
  font-size: 14px;
  margin-top: 2px;
}

.error[type='radio']:after {
  width: 13px;
  height: 13px;
  border-radius: 15px;
  top: -1px;
  left: 0px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #ff0000;
}

.error[type='checkbox']:after {
  width: 13px;
  height: 13px;
  color: #fff;
  top: -1px;
  left: 0px;
  position: relative;
  background-color: #fff;
  color: #ff0000;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #ff0000;
  border-radius: 2px;
}

/**/

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
}

.select {
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;
  min-width: 15ch;
  max-width: 50ch;
  border: 1px solid #dee2e6;
  border-radius: 0.25em;
  padding: 0.5em 0.7em;
  font-size: 1.2rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}
.select select,
.select::after {
  grid-area: select;
}
.select:not(.select--multiple)::after {
  content: '';
  justify-self: end;
  width: 0.8em;
  height: 0.8em;
  background-color: #dee2e6;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}
select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid #dee2e6;
  border-radius: inherit;
}
select[multiple] {
  padding-right: 0;
  height: 6rem;
}
select[multiple] option {
  white-space: normal;
  outline-color: #dee2e6;
}
.select--disabled {
  cursor: not-allowed;
  background-color: #dee2e6;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}
label {
  font-size: 1.125rem;
  font-weight: 500;
}
.select + label {
  margin-top: 2rem;
}

.radio-class {
  margin-right: 15px;
}

input[type='radio'] {
  margin-right: 15px;
  color: orange;
}
